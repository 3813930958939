/* status css */
.components-container {
  background-color: #fff;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  overflow: hidden;
  margin: 30px 0;
}
.components-container.two-columns .component-container {
  width: 50%;
  float: left;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-left-width: 0px;
  border-top-width: 0px;
  padding: 1.1rem 1.25rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0 0 0 0 !important;
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.125) !important;
  margin: 2px -1px -1px 1px;
}
.status-data-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.status-data-main .sttus-left {
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: 90%;
}
.status-data-main .sttus-left .status-title-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  width: 100%;
}
.status-data-main .sttus-left .status-title-area .status-title {
  color: #252525;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 600 !important;
  margin-bottom: 0;
  padding: 0;
  max-width: 92%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.status-data-main .sttus-left .status-title-area .status-question {
  border-radius: 99px;
  -moz-border-radius: 99px;
  -webkit-border-radius: 99px;
  -o-border-radius: 99px;
  -ms-border-radius: 99px;
  cursor: default;
 /* display: inline-block;
  */
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.125);
  height: 20px;
  width: 20px;
  font-size: 1rem;
  line-height: 18px;
  margin-left: 0;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.status-data-main .status-state {
  margin: 0;
  padding: 0;
}
.sttus-right .status-sign svg {
  font-size: 22px;
}
.sttus-right .status-sign.operational svg {
  color: #28a745;
}
.sttus-right .status-sign.not-responding svg {
  color: #eb335b;
}
.sttus-right .status-sign.pending svg {
  color: #fe8300;
}
@media only screen and (max-width: 767px) {
  .components-container.two-columns .component-container {
    width: 100%;
 }
  .status-data-main .sttus-left {
    max-width: 85%;
 }
  .status-data-main .sttus-left .status-title-area .status-title {
    font-size: 14px;
    max-width: 87%;
 }
}
