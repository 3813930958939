*****General CSS********** body {
    margin: 0;
    font-family: "IBM Plex Sans", sans-serif !important;
}

.w-10 {
    width: 10%;
}

.w-20 {
    width: 20%;
}

.w-30 {
    width: 30%;
}

.w-40 {
    width: 40%;
}

.w-50 {
    width: 50%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.w-100 {
    width: 100%;
}

body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 25px #202020; */
    box-shadow: inset 0 0 25px rgba(130, 130, 130, 1);
    border-radius: 5px;
}

body::-webkit-scrollbar-thumb {
    background-color: #052977;
    outline: 1px solid #202020;
    border-radius: 5px !important;
}

.MuiDataGrid-main ::-webkit-scrollbar {
    /* width: 2px; */
    height: 12px;
}

.MuiDataGrid-main ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 25px #202020; */
    box-shadow: inset 0 0 25px rgba(130, 130, 130, 1);
    border-radius: 5px;
}

.MuiDataGrid-main ::-webkit-scrollbar-thumb {
    background-color: #052977;
    outline: 1px solid #202020;
    border-radius: 5px !important;
}





.MuiBox-root .MuiDrawer-paperAnchorLeft::-webkit-scrollbar {
    width: 0.2em !important;
}

.MuiBox-root .MuiDrawer-paperAnchorLeft::-webkit-scrollbar-track {
    box-shadow: inset 0 0 15px #202020 !important;
    border-radius: 5px !important;
}

.MuiBox-root .MuiDrawer-paperAnchorLeft::-webkit-scrollbar-thumb {
    background-color: #052977 !important;
    outline: 1px solid #202020 !important;
}


.user-info-modal-main .right-content-main::-webkit-scrollbar {
    width: 10px !important;
}

.user-info-modal-main .right-content-main::-webkit-scrollbar-track {
    box-shadow: inset 0 0 15px #202020 !important;
    border-radius: 5px !important;
}

.user-info-modal-main .right-content-main::-webkit-scrollbar-thumb {
    background-color: #052977 !important;
    outline: 1px solid #202020 !important;
    border-radius: 5px !important;
}


body .mr-0,
body .mx-0 {
    margin-right: 0 !important;
}

body .ml-0,
body .mx-0 {
    margin-left: 0 !important;
}

.pbpx-20 {
    padding-bottom: 20px;
}

.lh-initial {
    line-height: initial !important;
}

.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell:focus {
    outline: solid transparent 1px !important;
}

.content-components {
    font-size: 16px;
    padding: 20px;
    position: relative;
    color: #1b2e4b;
    letter-spacing: -0.2px;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall {
    color: white !important;
}

.MuiCalendarPicker-root button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall {
    color: rgba(0, 0, 0, 0.54) !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23) !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiInputBase-root.Mui-focused {
    border-color: #4d90fe !important;
    border-width: 1px !important;
}

p.MuiTablePagination-displayedRows {
    margin-bottom: 0 !important;
}

.logo-image {
    width: 90px;
}

.bg-white {
    background-color: white !important;
}

.Toastify__toast--info {
    background: #052977;
}

.txt-secondary {
    color: #125a96;
}

.categories-box {
    margin-bottom: 25px;
}

.countries-box {
    margin-top: 15px;
}

.countries-box.col-xl-2 {
    padding-left: 7.5px !important;
    padding-right: 7.5px !important;
}

.countries-box .MuiPaper-root.MuiPaper-elevation {
    display: block;
    padding: 15px;
}

.countries-box .country-flag-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.countries-box .country-flag-box .country-flag {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 1px solid lightgray;
    margin-bottom: 8px;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-position: center;
    background-position: center;
    object-fit: cover;
}

.package-boxes-row {
    padding-bottom: 20px;
}


.category-header {
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.categories-box .categories-hd {
    font-size: 1rem;
    color: #052977;
}

.categories-box.cat-new-box .categories-hd {
    min-height: 45px;
}

.categories-box .MuiPaper-root.MuiPaper-elevation {
    display: block;
    padding: 15px;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
}

.categories-box .MuiCardMedia-media {
    width: 100%;
    height: 217px;
    border-radius: 4px;
    margin: 0px auto 8px auto;
    border: 1px solid #d9d9d9;
}

.categories-box.cat-new-box .MuiCardMedia-media {
    height: 110px;
}

.categories-box .MuiCardContent-root {
    text-align: center;
}

.categories-box .categories-box-buttons {
    display: flex;
    padding: 8px 0 0 0;
}

.user-limit-box {
    display: flex;
    align-items: center;
}

.categories-box .categories-box-buttons button {
    width: 100%;
    line-height: initial;
    height: 30px;
}

.categories-box .categories-box-buttons button .MuiSvgIcon-root {
    font-size: 15px;
}


.MuiAppBar-colorPrimary {
    box-shadow: none !important;
    border-bottom: 1px solid #c8c8c8;
}

.button-icon-small .MuiSvgIcon-root {
    font-size: 17px !important;
}

.btn-width {
    min-width: 150px !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    line-height: initial !important;
    height: 40px !important;
}

.btn-width .MuiSvgIcon-root,
.button-icon-small .MuiSvgIcon-root {
    font-size: 17px !important;
}

.btn-height-set {
    line-height: initial !important;
    min-height: 38px;
    ;
}

/* div#demo-multiple-checkbox {
    padding: 8.5px 14px;
} */
.field-style {
    width: 100%;
    background-color: white;
}

.field-style label {
    font-size: 0.9em;
}

.field-manual-style .inputFieldClass {
    padding: 8.5px 14px;
}

.search-bar-main {
    padding: 0 15px;
}

.pid-field-main {
    display: flex;
    gap: 0px;
}

.pid-field {
    width: 113px;
}

.pid-field fieldset {
    top: 0;
    border-radius: 5px 0 0 5px;
}

.pid-field legend {
    opacity: 0;
    display: none;
}

.pid-field3 {
    width: 100%;
}

.pid-field3 fieldset {
    top: 0;
}

.pid-field3 legend {
    opacity: 0;
    display: none;
}

.pid-field2 fieldset {
    /* top: 0; */
    border-radius: 0 5px 5px 0;
}

.admin-profile-box {
    /* background-color:#ffffff; */
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}




.admin-profile-pic {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-bottom: 15px;
    border: 1px solid #d9d9d9;
    padding: 8px;
    object-fit: cover;
}

.admin-profile-box h6 {
    text-align: center;
    margin-top: 10;
    position: relative;
    z-index: 2;
    color: #ffffff;
}

.admin-profile-box.userModal-profile {
    height: 140px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 15px;
    padding-left: 15px;
}
.admin-profile-box.userModal-profile .pic-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    padding-bottom: 10px;
}

.admin-profile-box.userModal-profile .admin-profile-pic {
    padding: 0;
    position: relative;
    z-index: 1;
}

.dashboard-user-details-box {
    position: fixed;
    bottom: 40px;
    right: 0px;
    align-items: center;
    padding: 20px 30px;
    min-width: 320px;
    border-radius: 6px 0 0 6px;
}

.admin-profile-box p {
    text-align: center;
    color: grey;
    font-size: 14px;
    margin-bottom: 0px;
}

.dash_icon {
    color: #125a96;
    background: whitesmoke;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.dash_icon .dollar-icon {
    font-size: 2rem;
    ;
}

.package-border {
    border-right: 1px solid #e0e0e0;

}

.not-found-txt {
    margin: 20px 0px;
    height: 400;
    display: flex;
    align-items: center;
    justify-content: center;
}

.txt-grey {
    color: #575757;
}

.fw-medium {
    font-weight: 500 !important;
}

.fw-semi-bold {
    font-weight: 600 !important;
}

.fw-bold {
    font-weight: 700 !important;
}

.fw-normal {
    font-weight: 400 !important;
}

.amount-txt {
    font-weight: 700;
}

.sidebar-main>div.MuiPaper-root {
    border-right: 1px solid #c8c8c8;
}

.MuiCollapse-vertical.drop-main {
    margin-top: 2px;
}

.dash-menu-main li .dash-menu-icon {
    min-width: 36px;
}

.dash-menu-main li .dash-menu-button {
    padding-top: 2px;
    padding-bottom: 2px;
}

.mb-15 {
    margin-bottom: 15px !important;
}


.dash-menu-main li .dash-menu-icon .MuiSvgIcon-root {
    font-size: 1.2rem;
}

.dash-menu-main li .dash-menu-button.second-level {
    padding-top: 3px;
    padding-bottom: 3px;
}

.dash-menu-main li .dash-menu-button.second-level .dash-menu-icon {
    min-width: 30px;
}

.dash-menu-main li .dash-menu-button.second-level .dash-menu-icon .MuiSvgIcon-root {
    font-size: 1rem;
}

.dash-menu-main li .dash-menu-button.second-level .dash-menu-icon.check .MuiSvgIcon-root {
    font-size: 1.2rem;
}


.dash-menu-main li .dash-menu-icon .crm-icon {
    font-size: 1.4rem;
}

.dash-menu-main li .dash-menu-icon .Campaign-icon {
    font-size: 1.5rem;
}

.dash-menu-main li .dash-menu-txt {
    margin-top: 3px;
    margin-bottom: 3px
}

.dash-menu-main li .dash-menu-txt span {
    font-size: 0.8rem;
    color: #000;
    font-family: 'Segoe UI';
}

.user-list-main>ul {
    padding-top: 0px;
}

.user-list-main li .user-list-txt span {
    font-size: 0.8rem;
}

.user-list-main ul li .user-list-icon {
    min-width: 40px;
}

.user-list-main.crm ul li .user-list-icon .MuiSvgIcon-root {
    min-width: 30px;
}

.user-list-main.crm ul li .user-list-icon .MuiSvgIcon-root {
    font-size: 1.2rem;
}

.MuiDataGrid-columnHeaders {
    background: #052977;
}


.drawer-second-content-main .pl-15.row {
    padding-left: 0px !important;
}

.drawer-second-content-main .mt-3 {
    margin-top: 0px !important;
}

.drawer-second-content-main hr.ml-15 {
    margin-left: 0px !important;
}

.drawer-second-content-main hr+.pl-15 {
    padding-left: 0px !important;
}

.drawer-second-content-main .bg-white.ml-15 {
    margin-left: 0px !important;
}

.drawer-second-content-main .campaign-rignt-content-main {
    margin: 0px !important;
}


.package-box-main {
    width: 100%;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.package-box-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px 6px 0 0;
}

.package-box-title .package-title {
    text-transform: uppercase;
    color: white;
    margin: 5px 0px 4px 0px;
    font-weight: 400;
    font-size: 1rem;
}

.package-box-info .user-hd {
    font-size: 1rem;
}

.package-box-info .total-hd {
    font-size: 1rem;
}

.package-box-info {
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
}

.generic-hd {
    padding-bottom: 30px;
    padding-top: 15px;
}

.generic-hd2 {
    padding-bottom: 0px;
}


.modal-generic-style {
    width: 50%;
}

.modal-generic-style .upload-reset-buttons {
    font-size: 11px;
    height: 100%;
    width: 100%;
}

.modal-generic-style .add-icon-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.modal-generic-style .modal-hd {
    text-align: center;
    margin: 0px;
    padding-bottom: 20px;
}

.tag-style-main span.tag-wrapper.tagClass {
    margin-left: 0px;
}

.tag-style-main span.tag-wrapper.tagClass {
    padding: 5px 5px 5px 10px;
}

.multiple-checkbox-style .MuiInputLabel-formControl {
    top: -6px;
}

.multilist-main .css-cveggr-MuiListItemIcon-root {
    min-width: 25px;
}

.multilist-main .css-1ls8oq2-MuiSvgIcon-root {
    font-size: 1rem;
}

.btn-gen-margin {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.btn-gen-margin.ml-0 {
    margin-left: 0px !important;
}

.grey-btn-outline.btn-gen-margin,
.grey-btn-outline {
    border: 1px solid rgba(128, 128, 128, 0.5) !important;
    color: rgba(128, 128, 128, 1) !important;
}

.grey-btn-outline.btn-gen-margin:hover,
.grey-btn-outline:hover {
    border: 1px solid rgba(128, 128, 128, 0.6) !important;
}

.boxes-buttons-row {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    justify-content: left;
    align-items: center;
}

.boxes-buttons-row2 {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    justify-content: left;
    align-items: center;
}


.modal-buttons-row {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    justify-content: left;
    align-items: center;
}


.content-components>.container {
    padding: 0;
    max-width: 100%;
}

.content-components pre[class*="language-"],
.content-components code[class*="language-"] {
    border-color: rgba(72, 94, 144, 0.16);
    font-family: "Roboto Mono", monospace;
    font-size: 12px;
    letter-spacing: normal;
    background-color: #f8f9fc;
}

.logo {
    max-width: 200px;
}

.navbar-header {
    height: 60px !important;
}

.btn-package {
    margin-top: 30px;
}

.table-width .table th,
.table-width .table td {
    vertical-align: middle;
}

.cat-image img {
    width: 80px;
    height: 60px;
    object-fit: cover;
}

.modal-dialog {
    max-width: 800px !important;
}

.cardmodel .custom-control-label::after {
    left: -14px !important;
}

.cardmodel .checkbox-switch .bg-white {
    padding-top: 10px;
    padding-bottom: 0;
}

#root>.MuiBox-root {
    background-color: #f9fbfd;
}

.users-main-container.container {
    /* padding-left: 0px;
    padding-right: 0px; */
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.users-main-row {
    height: 100vh;
}

.left-column.col-xl-2,
.left-column.col-xl-auto {
    border-right: 1px solid #c8c8c8;
    padding: 0;
    background-color: white;
}

.left-column.col-xl-auto {
    min-width: 190px;
}

/* .right-column.col-xl-9 {
    background-color: white;
     position: sticky;
    top: 64px; 
} 
*/
.left-content-main {
    position: sticky;
    top: 65px;
    padding: 0px;
}

.right-content-main {
    padding: 15px 0px;
}

.users-card-main {
    padding: 20px;
    min-height: 300px;
}

.users-card-main.addons {
    min-height: initial;
    min-width: initial;
    padding-top: 12px;
}


.addons-cart-title {
    margin-bottom: 5px;
}

.users-card-main.addons .addons-cart-title .tag-icon {
    margin-right: 10px;
}

.addon-mute-txt {
    font-size: 0.8rem;
    color: grey;
}

.country-card-main {
    padding: 20px;
}

.users-card-main .MuiCardContent-root {
    padding-left: 0px;
    padding-right: 0px;
}

.users-card-main.campaign-modal .MuiCardContent-root {
    padding: 3px 16px 3px 16px;
}

.users-card-main.campaign-modal .campaign-info-data {
    color: rgba(0, 0, 0, .85);
}

.user-info-column {
    color: #4a4a4a;
}

.user-info-column.new {
    font-size: 0.9rem;
}

.user-info-column.new .info-hd {
    color: black;
    padding-right: 5px;

}


.users-card-main.addons.balance-box {
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 1px #ededed;
    padding: 15px 10px;
    border-radius: 7px;
}

.users-card-main.addons.white-box {
    background-color: #052977;
}

.users-card-main.addons.white-box .addons-cart-title,
.users-card-main.addons.white-box .balance-box-amount {
    color: #ffffff;
}


.modal-spacing-new {
    padding: 10px 20px 15px 20px !important;
}


header.MuiPaper-root>.MuiToolbar-root {
    padding-left: 15px;
    padding-right: 15px;
}

.user-name-area {
    margin-bottom: 0px;
    padding: 0.4rem 0;
    border-bottom: 1px solid #c8c8c8;
}

.user-name-area .user-icon {
    margin-right: 10px;
    ;
}

.campaign-name-area {
    margin-bottom: 0px;
    padding: 0 0 0.5rem 0;
    border-bottom: 1px solid #c8c8c8;
}

.campaign-name-area .user-icon {
    margin-right: 10px;
    ;
}

.search-txt-area {
    padding: 25px;
    text-align: center;
    min-height: 300px;
}

.search-txt-main {
    margin: 0 auto;
    display: table;
}

.search-txt-main .search-icon {
    margin-bottom: 10px;

}

.filter-clear-col {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.countries-listing-main {
    padding: 0 7.5px;
}

.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.custom-cat-listing-thumbs-top {
    padding: 8px;
    margin: 8px 5px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-cat-listing-thumbs-top img.cat-thmb-small {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    object-fit: cover;
}

.cus-cat-box-inner {
    border: 1px solid #f2f2f2;
    margin: 10px 10px;
    padding: 10px;
    border-radius: 5px;
}

.cus-cat-hd-div {
    margin-top: 20px;
    padding: 0px 15px;
}

.cus-cat-hd-div h6 {
    color: #7c7c7c;
    font-size: 1.1rem;
}

.cus-cat-user-info-box-top {
    width: 100%;
    display: flex;
    align-items: top;
}

.cus-cat-user-info-box-top .cus-cat-user-info-img-main {
    border-radius: 7px;
    /* margin: 1px; */
    height: 102px;
    width: 102px;
    margin-left: 10px;
    margin-top: 10px;
    border: 1px solid lightgrey;
}

.cus-cat-user-info-img {
    border-radius: 7px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.custom-categories-row-main {
    margin-bottom: 15px;
}

.search-here-text-main {
    text-align: center;
}

.modal-head-icon-area {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0px;
}

.modal-title {
    text-align: center;
    margin: 20px 0px;
}

.modal-description {
    text-align: center;
}

.campaigns-listing-main>div {
    gap: 7.5px !important;
}

.campaigns-listing-main .campaigns-media-img {
    padding: 0px 12px;
}

.campaigns-listing-main .campaigns-media-img img {
    border-radius: 8px;
    border: 1px solid #d9d9d9;
}

.campaigns-listing-main .campaigns-sub-title-area {
    margin: 0px 15px;
}

.campaigns-sub-title-area .camp-sub-title {
    font-size: 0.875rem;
    padding: 10px 0px;
    color: #000;
}

.campaigns-sub-title-area .camp-sub-date {
    font-size: 0.875rem;
    color: gray;
}

.campaigns-title-area .MuiCardHeader-title {
    font-weight: 500;
}

.campaigns-title-area .MuiAvatar-root {
    border: 1px solid #d9d9d9;
}

.campaign-box-listing li span.MuiTypography-root,
.campaign-box-listing li p.MuiTypography-root {
    font-size: 0.9rem;
}

.category-modal-thumb {
    border: 1px solid #d9d9d9;
}

.category-modal-thumb img {
    border-radius: 50%;
}

.category-modal-thumb.new-thumb-style {
    width: 100% !important;
    height: 150px !important;
    border-radius: 4px !important;
    margin: 0px auto 0px auto !important;
    border: 1px solid #d9d9d9 !important;
}

.category-modal-thumb.new-thumb-style img {
    border-radius: 0%;
}

.color-red {
    color: red;
}

.color-green {
    color: green;
}

.featuredImg .close-btn-red {
    font-size: 1rem;
}

/* .featuredImg .title-card { 
    line-height: 4px;
} */
.user-info-modal-main {
    position: absolute;
    bottom: 0%;
    right: 0%;
    background-color: white;
    /* box-shadow: none; */
    width: calc(100% - 240px);
    height: calc(100% - 65px);
    border-radius: 16px;
    /* padding: 32px; */
}

.user-info-modal-main.full-screen {
    width: 100%;
    height: 100%;
    border-radius: 0;
}

.user-info-modal-main .right-content-main {
    height: calc(100vh - 130px);
    overflow-x: hidden;
    padding-right: 15px;
    /* overflow-y: scroll; */
}

.user-info-modal-main.full-screen .right-content-main {
    /* height: calc(100vh - 30px); */
    height: calc(100vh - 60px);
}

.user-info-modal-main .user-list-main .MuiList-padding {
    padding-top: 0px !important;
}

.user-info-modal-main .left-content-main {
    top: 65px;
}

.user-info-modal-main.full-screen .left-content-main {
    top: 53px;
    /* top: 0; */
}

.user-info-modal-main .left-column.col-xl-2 {
    background-color: transparent !important;
}

.user-list-main li:first-child.Mui-selected {
    border-radius: 8px 0 0 0;
}

.user-list-main.crm li:first-child.Mui-selected {
    border-radius: 0 0 0 0;
}

.user-info-modal-main .user-name-area {
    background-color: transparent !important;
}

.modal-listing-min-window {
    position: sticky;
    bottom: 0%;
    right: 0%;
    background-color: white;
    width: calc(100% + 15px);
    /* min-height: 46px; */
    border: 1px solid #e0e0e0;
    border-bottom: 0px;
    margin: 10px 0 0 0;
    border-radius: 3px 3px 0 0;
    -webkit-box-shadow: 0px -5px 13px -12px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px -5px 13px -12px rgba(0, 0, 0, 0.4);
    box-shadow: 0px -5px 13px -12px rgba(0, 0, 0, 0.4);
}

.modal-listing-min-window.campaign-bottom-bar {
    position: fixed;
    width: calc(100% - 240px);
}

.min-window-sub-box {
    padding: 0px;
}

.modal-listing-min-window .min-chip:first-child {
    border-left: 0;
}

.modal-listing-min-window .min-chip {
    background: white;
    border-radius: 0;
}

.modal-listing-min-window .min-chip .chip-title {
    font-size: 0.8rem;
    padding: 4px 10px;
}

.modal-listing-min-window .min-chip:hover .chip-title {
    cursor: pointer;
    background-color: #f5f5f5;
}

.modal-listing-min-window .min-chip .chip-icon-window {
    padding: 0 10px;
    background-color: #e7e7e7;
    color: white;
    cursor: pointer
}

.modal-listing-min-window .min-chip .chip-icon-window .MuiSvgIcon-root {
    font-size: 0.9rem;
    color: #ababab;
}

.modal-listing-min-window .min-chip .chip-icon-cancel {
    padding: 0 10px;
    background-color: #d12d33;
    color: white;
    cursor: pointer
}

.modal-listing-min-window .min-chip .chip-icon-cancel .MuiSvgIcon-root {
    font-size: 0.9rem;
    color: white;
}

.pl-15 {
    padding-left: 15px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.account-name-hover-style {
    color: #1665d8;
}

.account-name-hover-style:hover {
    cursor: pointer;
    color: #0148ae;
}

.MuiCircularProgress-indeterminate {
    z-index: 9999;
}

.action-buttons-div {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.action-buttons-div .MuiButton-root {
    min-width: 40px;

}

.not-connected {
    margin: 0;
    color: red;
}

div.premission-title-bar {
    margin-bottom: 10px;
    font-weight: 400;
}


div.title-bar-box-main {
    margin-bottom: 0px;
}

div.title-bar-hd-main {
    margin-bottom: 0px;
}

.campaign-box-listing.modal-listing {
    padding: 0;
}

.campaign-box-listing.modal-listing li {
    padding: 4px 0;
}

/* .campaign-box-listing.modal-listing  li:first-child {
 padding-top: 0;
} */
/* .campaign-box-listing.modal-listing  li:first-child .css-tlelie-MuiListItemText-root {
    margin-top: 0px;
} */
.campaigns-modal-media-img {
    margin: 0;
    /* border: 1px solid #d9d9d9; */
    padding: 0;
    border-radius: 4px;
}

.campaigns-modal-media-img img {
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    width: 150px;
    height: 150px;
    padding: 1px;
}

.users-card-main.modal-info {
    min-height: initial !important;
}

/* .user-info-column.campaign-modal-column .MuiCardContent-root{
        padding-bottom:0!important;
    } */
.campaign-modal-title-area .campaign-modal-title {
    font-size: 0.9rem;
    padding: 10px 0px 15px 0px;
    color: #242424;
    font-weight: 500;
}

.signin-btn .MuiLoadingButton-loadingIndicator {
    color: white !important;
}

div.MuiTablePagination-root {
    padding-right: 4px !important;
}

/* .MuiTablePagination-actions .MuiIconButton-root:hover{
        background-color: #052977;
        color: white;
    } */

.paper-content-generic-main {
    padding: 20px;
    border-radius: 7px;
}

.campaign-rignt-content-main {
    margin: 15px 15px 40px 15px;
    border: 1px solid #c8c8c8;
    border-radius: 16px;
    /* height: calc(95vh - 30px); */
}

.user-status-style {
    color: white;
    padding: 1px 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    height: 25px;
    font-size: 14px;
}

.country-info-data-campaign-box {
    height: 100%;
}


.country-info-data-campaign .country-campaign-detail tr:last-child td {
    border-bottom: 0px !important;
}

.total-hd {
    font-size: 1rem !important;
}

.total-hd.mb-0 {
    margin: 0px !important;
}

.total-hd span {
    min-width: 200px;
    color: grey;
}

.total-hd.mb-3 {
    margin-bottom: 1rem !important;
}

.featuredCategory .MuiDataGrid-iconButtonContainer {
    display: none;
}

.featuredCategory .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: center !important;
}

.shipping-info-line {
    display: flex;
    grid-gap: 5px;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 2px;
    margin-bottom: 2px;

}

.shipping-info-line.no-border {
    border-bottom: 0;
}

.shipping-info-line .MuiCheckbox-colorPrimary {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.ship-info-hd {
    font-weight: 500;
    min-width: 60px;
}

.shipping-new .col-lg-12.mb-3:last-child {
    margin-bottom: 0 !important;
}


/* responsive */
@media (max-width: 576px) {
    .justify-content-sm-center {
        justify-content: center !important;
    }
}

@media only screen and (max-width: 1620px) and (min-width: 1200px) {
    .categories-box.col-xl-2 {
        flex: 0 0 25% !important;
        max-width: 25% !important;
    }

    .countries-box.col-xl-2 {
        flex: 0 0 25% !important;
        max-width: 25% !important;
    }

}

@media (min-width: 900px) {

    .right-content-main {
        position: sticky;
        top: 65px;
    }

    .user-info-modal-main .right-content-main {
        position: static;
        top: initial;
    }
}

@media (min-width: 992px) {
    .content-components {
        padding: 25px;
        margin-top: 60px;
        margin-left: 240px;
    }
}

@media (min-width: 1200px) {
    .content-components {
        padding: 30px;
        /* margin-right: 240px; */
    }
}

@media only screen and (max-width: 1680px) {
    .modal-generic-style {
        width: 80% !important;
    }
}

@media only screen and (max-width: 1600px) {}

@media only screen and (max-width: 1440px) {

    .user-info-column.campaign-data-box1 {
        flex: 100%;
        max-width: 100%;
    }

    .user-info-column.campaign-data-box2 {
        flex: 50%;
        max-width: 50%;
        margin-top: 15px;
    }

    .user-info-column.campaign-data-box3 {
        flex: 50;
        max-width: 50%;
        margin-top: 15px;
    }


    .login-status-col {
        width: 100% !important;
    }
}

@media only screen and (max-width: 1366px) {}

@media only screen and (max-width: 1280px) {
    .user-info-column.campaign-data-box1 {
        flex: 100%;
        max-width: 100%;
    }

    .user-info-column.campaign-data-box2 {
        flex: 100%;
        max-width: 100%;
    }

    .user-info-column.campaign-data-box3 {
        flex: 100;
        max-width: 100%;
    }

    .users-card-main {
        padding: 10px;
        min-height: initial;
    }

    .user-info-column {
        font-size: 0.8rem;
    }

    .users-card-main .MuiTypography-h6 {
        font-size: 1rem;
    }
}

@media screen and (max-width: 900px) {
    .sumContainer {
        width: initial !important;
    }

    .admin-profile-box {
        height: initial;
        padding: 10px 10px;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #e5e5e5;
    }

    .admin-profile-pic {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin-bottom: 10px;
    }

    .admin-profile-box p {
        font-size: 12px;
    }

    .dash-menu-main li .dash-menu-txt span {
        font-size: 0.7rem;
    }

    .dash-menu-main li .dash-menu-icon .MuiSvgIcon-root {
        font-size: 1rem;
    }

    .dash-menu-main li .dash-menu-icon {
        min-width: 30px;
    }

    .dash-menu-main li .dash-menu-button.second-level .dash-menu-icon.check .MuiSvgIcon-root {
        font-size: 1rem;
    }

    .modal-listing-min-window.campaign-bottom-bar {
        width: 100%;
    }

    .mb-4,
    .my-4 {
        margin-bottom: 1rem !important;
    }

    .user-info-modal-main {
        position: absolute;
        bottom: 5%;
        right: initial;
        width: 90%;
        height: 90%;
        top: 5%;
        left: 5%;
        -moz-transform: translate(-5%, -5%);
        -ms-transform: translate(-5%, -5%);
        transform: translate(-5%, -5%);
        background-color: #fff;
        box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
        border-radius: 8px;
    }

    .user-info-modal-main.mobile-screen {
        width: 90%;
        height: 90%;
    }

    .users-main-row {
        height: 100%;
    }

    .search-txt-area h5.MuiTypography-root {
        font-size: 1rem;
    }

    .search-txt-area span.fw-normal {
        font-size: 0.8rem;
    }

    .user-info-modal-main .left-column.col-xl-2 {
        background-color: white !important;
        border-radius: 16px 0 0 16px;
    }

    .user-info-modal-main .right-column.col-xl-10 {
        background-color: white !important;
        border-radius: 0 16px 16px 0;
    }

    .btn-width {
        min-width: initial !important;
        min-height: initial !important;
    }

    main.MuiBox-root {
        padding: 0px;
    }

    .left-content-main {
        position: static;
        top: 0px;
    }
}


.user-info-modal-edit {
    position: absolute;
    bottom: 0%;
    right: 0%;
    background-color: white;
    width: calc(100% - 16.66667%);
    height: calc(100% - 53px);
    border-radius: 0;
    border: 0;
}

.modal-edit {
    width: 100%;
    padding: 0px;
}

.noReviews {
    text-align: center;
    margin-top: 12rem;
    font-size: 1.5rem;
    color: gray;
}

.user-info-modal-edit .box-style-new {
    border-radius: 0;
    border: 0;
}

.time {
    border-radius: 3px;
    color: rgb(255, 255, 255);
    /* background: #138636; */
    background: #008000;
    width: 100%;
    text-align: center;
    font-weight: 500;
    padding: -5px;
    font-size: 0.8rem;
}

.timeBad {
    background: #c91432;
}

.timeWarn {
    background: #ffbc11;
}


@media screen and (max-width: 768px) {
    .table-width table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }
}

@media screen and (max-width: 767px) {
    div.title-bar-hd-main {
        font-size: 1.2rem;
    }

    div.premission-title-bar {
        font-size: 1rem;
    }

    .package-box-title .package-title {
        margin: 5px 0px;
        font-size: 1rem;
    }

    .package-box-info {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .package-box-info .dash_icon {
        margin-bottom: 0.5rem !important;
    }

    .package-box-info .user-hd {
        font-size: 0.9rem;
        margin-bottom: 0.5rem !important;
    }

    .package-box-info .total-hd {
        font-size: 0.9rem;
        margin-bottom: 0.5rem !important;
    }
}



@media screen and (max-width: 576px) {
    .users-main-row {
        display: block;
    }

    .user-info-modal-main .right-content-main {
        max-height: 250px;
    }

    .right-content-main.campaign-modal-right-content {
        max-height: initial;
        height: calc(80vh - 130px);
    }

    .user-list-main ul li .MuiListItemButton-root {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .hamburger-btn {
        margin-right: 8px !important;
    }

    .icon-style-for-mob .MuiButton-endIcon {
        margin-left: 0px;
        margin-right: 0px;
    }

    .icon-style-for-mob .btn-gen-margin {
        height: initial !important;
    }
}

.discountType {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding-left: 10px;
    /* color:"" */
}

.discountType-checkbox span {
    font-size: 0.9rem !important;
}

.locationMap {
    /* height: calc(100vh - 30px); */
    /* overflow-x: hidden; */
}

.map-height>div {
    min-height: 202px;

}

.custom-close-btn-modal {
    position: absolute !important;
    right: 5px;
    top: 5px;
}

.dashboard-package {
    margin: 10px 0 10px 0;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.amcharts-chart-div a {
    display: none !important;
}

.hd-bottom {
    border-bottom: 1px solid #dbddde !important;
    padding: 0 !important;
}

.users-card-main.subCategoryBox {
    padding-top: 10px;
    padding-bottom: 0;

}

.users-card-main.subCategoryBox .MuiCardContent-root {
    padding-left: 16px;
    padding-right: 16px;
}

.users-card-main.subCategoryBox .cus-cat-user-info-box-top .cus-cat-user-info-img-main {
    margin-left: 0px;
    margin-bottom: 10px;
    width: 90px;
    height: 55px;
}

.users-card-title {
    padding-top: 10px;
    padding-bottom: 0;

}

.users-card-title .MuiCardContent-root {
    padding-left: 16px;
    padding-right: 16px;
}

.users-card-title .cus-cat-user-info-box-top .cus-cat-user-info-img-main {
    margin-left: 0px;
    margin-bottom: 10px;
    width: 90px;
    height: 55px;
}

.sub-cat-title2 {
    display: flex;
    align-items: center;
}

.sub-cat-title2 span {
    font-weight: 400;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.6);
    padding: 2px 0 0 7px;
}

.subCat-sub-title {
    font-size: 1rem !important;
}

.modal-btn-position {
    position: absolute !important;
    top: -27px;
    right: -27px;
}

.custom-btn {
    color: #fff;
    background-color: #052977;
    font-size: 0.875rem;
    padding: 6px 16px;
    border-radius: 4px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
}

.custom-btn:hover,
.custom-btn:focus {
    color: #fff;
    background-color: #052977;
}

.adManageImage {
    width: 100%;
    overflow: hidden;
    height: 110px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid #c4c4c4 !important; */
    border-radius: 5px;
}

.adManageImage img {
    object-fit: cover;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 110px;
    margin: 0;
}

.adManageRow {
    margin-top: 20px;
    background-color: white;
    /* padding: 10px 0; */
    /* border: 1px solid #c4c4c4; */
    border-radius: 5px;
    display: table;
}

.adManageRow>td:first-child {
    border-top-left-radius: 5px;
    background: #052977;
    color: #ffffff;
}

.adManageRow>td:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: 0px;
}

.adManageRow>td:first-child {
    border-bottom-left-radius: 5px;
}

.adManageRow>td:last-child {
    border-bottom-right-radius: 5px;
}


.adManageRow:first-child {
    margin-top: 0px;
}

.adManageCol {
    padding: 10px 5px;
    height: 100%;
    border-left: 1px solid #c4c4c4;
}

.adManageRow-HD {
    width: 200px;
    min-width: 200px;

}

.tablestyle1 {
    /* border-collapse: collapse; */
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
    border-collapse: separate;
    border-spacing: 0;
}

.tablestyle1>th {
    padding: 9px 8px;
    background-color: #595959;
    color: white;
    border: 1px solid #fff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    text-align: center;
    width: 50%;
}

.tablestyle1>tr {
    background-color: white;
}

.tablestyle1 tbody>tr>td {
    border: 1px solid #e1e1e1;
    /* padding: 9px 8px; */
    font-size: 14px;
    vertical-align: middle;
}

.tablestyle1>tr>td:nth-child(1) {
    text-align: left;
    padding-left: 25px;
}


.tablestyle2 {
    width: 100%;
}

.tablestyle2>tr>td {
    border-left: 1px solid #e1e1e1;
    padding: 8px 8px;
    font-size: 14px;
    vertical-align: middle;
    width: 150px;
    height: 126px;
}

.tablestyle2>tr>td:first-child {
    border-left: 0px;
}

.tableDisplayImage {
    /* min-width: 166px; */
    width: 100%;
    overflow: hidden;
    height: 126px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid #dbdbdb !important; */
    border-radius: 0;
    margin-bottom: 7px;
    border-bottom: 1px solid #e0e0e0;
}



.tableDisplayImage img {
    object-fit: cover;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 126px;
    border-radius: 0;
    margin: 0;

}

.tableDisplayImage.featuredImage {
    /* border: 0; */
    /* border: 1px solid #e0e0e0; */
}

.tableDisplayImage.featuredImageFull img {
    width: 100%;
}

.tableDisplayImage.iconImage img {
    width: 100%;
    height: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.edit-icon-btn {
    color: #052977;
}

.delete-icon-btn {
    color: #d32f2f;
}

.upload-icon-btn {
    padding: 5px 16px 3px 16px !important;
    font-size: 12px !important;
}

.upload-icon {
    font-size: 18px !important;
}

.tablestyle2.tablestyle2-12 .tableDisplayImage {
    min-width: 103px;
}

@media only screen and (max-width: 1680px) {
    .tablestyle1 {
        padding: 0 10px 0 0;
    }
}

.adContainer {
    border-radius: 5px;
}

.bannerContainer {
    /* padding: 10px 0 10px; */
}

.bannerName {
    margin-left: 10px;
}


.adManageImage.brandYouLoveIMG {
    height: 152px;

}

.adManageImage.brandYouLoveIMG img {
    max-height: 152px;
}

.slotBooked {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    background: #e5e5e5;
    min-height: 100px;
    justify-content: center;
}

.slotTitle {
    margin-bottom: 5px;
    border-bottom: 1px solid #d1d1d1;
}

.slotTitle,
.slotPrice {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.slotTitleUn {
    width: 100%;
    margin-bottom: 5px;
    border-bottom: 1px solid #d1d1d1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slotPriceUn {
    display: flex;
    justify-content: space-between;
    width: 100%;
}


.editBTN:hover {
    cursor: pointer;
}

.inputPrice {
    width: 55px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    height: 25px;
}

/* .dateBox{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
} */

.MuiDataGrid-columnHeaders {
    min-height: 40px !important;
    max-height: 40px !important;
    line-height: 40px !important;
}

.MuiDataGrid-virtualScroller {
    margin-top: 40px !important;
}

.MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle {
    font-weight: 400;
}

.super-app-theme--header {
    background-color: #052977;
    color: white;
}

.txt-btn-style {
    height: 30px !important;
    font-size: 11px !important;
    padding: 7px 16px 4px 16px !important;

}

.approved {
    background-color: #2e7d32 !important;
    border-color: #2e7d32 !important;
}

.reject {
    background-color: #d32f2f !important;
    border-color: #d32f2f !important;
}

/* saleFile  */
.saleContainer {
    display: flex;
    flex-direction: column;
}

.second-row {
    display: flex;
}

.buttons-sales {
    margin: 10px 0 20px 0;
}

hr.separater-sale {
    margin: 0 0 15px 0;
}

.MuiDataGrid-columnHeaderTitleContainer {
    color: #FFF !important;
}

.buttons-sales .Mui-disabled.btn-gen-margin {
    background-color: #798892;
    color: white;
    opacity: 0.65;
}

.dateBox {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.infoSection {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px;

}

.infoSection.new {
    padding: 15px 15px 10px 15px;
    border: 1px solid #efefef;
    -webkit-box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 5%);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 5%);
}


.titleView {
    margin: 10px 0 5px 5px;
}

.mainLoad {
    height: 100%;
}

.titleLifetime {
    padding: 15px;
}

.head {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 500;
    color: #000 !important;
    width: 100%;
    margin-bottom: 5px;
    border-bottom: 1px solid #e1e0e0;
}

.head:last-child {
    border: none;
}

.BalanceCard {
    border-radius: 10px !important;
}

.BalanceCard .MuiCardHeader-title {
    font-size: 1.25rem !important;
}

.comFooter {
    /* border: 1px solid #dbddde; */
    width: auto;
    padding: 10px;
    display: inline-block;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    background: #fff;
    margin-bottom: 10px;


}

.secFooter {
    display: flex;
    padding: 1px;
}

.footHead {
    width: 175px;
    color: #808080;
    font-weight: 500;
    text-align: right;
    margin-right: 15px;
}

.footBody {
    width: auto;
    color: #052977;
    font-weight: 500;
    width: 70px;
}

.grayColor {
    color: #000;
    font-weight: 500;
}

.mainFooterSale {
    margin: 15px 0px 0px 15px;
}

.actBtn {
    color: "#bf0000"
}

.actBtn:hover {
    cursor: pointer;
}

.eventCardDiv {
    font-size: 0.7rem;
    display: flex;
    justify-content: space-between;
}

.event-title {
    padding: 10px 0px;
    height: 65px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.6;
}

.eventCardDivIcons {
    display: flex;
    align-items: flex-end;
}

.eventCardDivDec {
    font-size: 0.7rem;
    display: flex;
    justify-content: space-between;

}

.tabEventImage {
    background: linear-gradient(180deg, #212B5C 0, #000C40);
    position: relative;
}

.tabEventTitle {
    position: absolute;
    left: 10px;
    top: 8px;
    background: #ffffff;
    padding: 1px 8px 1px 8px;
    border-radius: 15px;
    font-size: 0.65rem;
    font-weight: 600;
    color: #172153;
    text-transform: capitalize !important;
}

.newCatName {
    display: flex;
    justify-content: center;
    display: flex;
    justify-content: center;
    min-height: 110px;
    height: 100px;
    max-height: 110px;
    align-items: center;
    border: 1px solid #d9d4d4;
    border-radius: 5px;
}

.sumContainer-height-190 {
    min-height: 210px;
}

.sumContainer-width-235 {
    min-height: 235px;
}

.sumContainer {
    margin-top: 10px;
    padding: 12px 12px 5px 12px;
    border: 1px solid #e5e0e0;
    width: 30rem;
    border-radius: 5px;
}

.sumTitle {
    border-bottom: 1px solid #dfdfdf;
    /* font-size: 1.1rem; */
    font-weight: 400;
    margin-bottom: 5px;
}

.sumBodyRow {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 3px;
}

.sumBodyTitle {
    color: gray
}

.sumBody:last-child {
    border-bottom: none;
}

.nb {
    border: none;
    margin: 0;
}

.orderBTN {
    color: #052977;
    text-decoration: underline;
    font-weight: 500;
    font-size: 0.9rem;
    cursor: pointer;
}


.td-width-60 {
    min-width: 60px;
    max-width: 60px;
    width: 60px;
}

.td-width-70 {
    min-width: 70px;
    max-width: 70px;
    width: 70px;
}

.td-width-80 {
    min-width: 80px;
    max-width: 80px;
    width: 80px;
}

.td-width-90 {
    min-width: 90px;
    max-width: 90px;
    width: 90px;
}

.td-width-100 {
    min-width: 100px;
    max-width: 100px;
    width: 100px;
}

.td-width-110 {
    min-width: 110px;
    max-width: 110px;
    width: 110px;
}

.td-width-120 {
    min-width: 120px;
    max-width: 120px;
    width: 120px;
}

.td-width-130 {
    min-width: 130px;
    max-width: 130px;
    width: 130px;
}

.td-width-140 {
    min-width: 140px;
    max-width: 140px;
    width: 140px;
}

.td-width-150 {
    min-width: 150px;
    max-width: 150px;
    width: 150px;
}

.td-width-200 {
    min-width: 200px;
    max-width: 200px;
    width: 200px;
}

.order-detail-title-area {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 10px 0;
    padding: 0;
    position: relative;
}

.order-detail-title-area .order-num {
    display: flex;
    font-family: Nunito Sans, sans-serif;
    font-size: 14px;
    font-weight: 700;
    gap: 5px;
}

.order-detail-title-area .order-name {
    font-weight: 400;
}

.order-detail-title-area .order-date {
    font-size: 14px;
}

.transactions-box {
    background: #fff;
    border: 1px solid #d6dee5;
}

.transactions-box thead {
    background: #052977;
    color: #fff;
}

.transactions-box thead tr th {

    font-weight: 700;
    font-size: 14px;
    padding: 0.3rem 0.75rem;
    text-transform: none;
}

.transactions-box tbody tr td {
    font-size: 12px;
    padding: 0.1rem 0.75rem;
    vertical-align: middle;
}

.order-sum {
    border: 1px solid #e1e3e5;
    border-radius: 5px;
    box-shadow: 0 0 0.2125rem rgba(23, 24, 24, .05), 0 0.0625rem 0.125rem rgba(0, 0, 0, .15);
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin: 0px 0 0 0;
    width: 100%;
}

.order-sum .paid-mid-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3px 0;
    font-size: 14px;
}

.order-sum .paid-mid-row .multi-content {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: space-between;
}

.order-sum .paid-mid-row .multi-content .left-hd {
    min-width: 120px;
}

.order-sum .paid-mid-row .multi-content .left-hd {
    min-width: 120px;
}

@media only screen and (max-width: 767px) {
    .order-sum .paid-mid-row .multi-content .left-hd {
        min-width: initial;
    }
}

.marketFeesContainer {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.marketFeesDiv {
    width: 18rem;
    height: 6rem;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid #dbdbdb;
}

.descValue {
    text-align: right;
    font-size: 1.2rem;
    font-weight: 500;
}

.descTitle {
    color: #a9a7a7;
    font-size: 0.9rem;
}

.marketFeeIcon {
    font-size: 3.5rem !important;
    color: #d1cdcd !important;

}

.marketFeeDesc {
    padding: 20px 15px;
}

.dashboard-info-box {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid #dbdbdb;
    margin-bottom: 24px;
    padding: 15px 20px 15px 50px;
    position: relative;
    text-align: right;
}

.dashboard-info-box .info-icon-main {
    bottom: 0;
    color: #e4e7ed;
    font-size: 2.1em;
    left: 10px;
    margin: auto;
    position: absolute;
    top: 0;
}

.dashboard-info-box .value-info-hd {
    color: #575757;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.5;
}

.dashboard-info-box .total-info-hd {
    color: #798892;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
}

.earningTitle {
    font-weight: 500;
    font-size: 1.2rem;
    margin: 10px 0 5px;
}

.cardNameContainer {
    position: relative;
}

.cardName {
    position: absolute;
    font-size: 0.8rem;
    color: gray;
    width: 200px;
    top: 6px;
    margin-left: 5px;
}

.divider-line {
    border-top: 1px solid #d5d5d5;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.resetBTN {
    margin-top: 10px !important;
}

.linkBrand {
    color: #000084 !important;
}


.summary-space {
    height: 30px;
}

.stackDiv {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.feedCount {
    color: #8c8c8c;
    font-size: 0.8rem
}

.feeds {
    margin-top: 5px;
    height: 26rem;
    min-height: 26rem;
    overflow-y: auto;

}

.feeds::-webkit-scrollbar {
    width: 3px;
}

.feeds::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

.feeds::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    /* outline: 1px solid slategrey; */
}

.videoBox {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 0 10px;
    margin: 5px 0;
    height: 7rem;
    min-height: 7rem;
    display: flex;
    gap: 10px;
    align-items: center;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px;

}

.videoBox:first-child {
    margin-top: 0 !important;
}

.videoBox:last-child {
    margin-top: 0 !important;
}

.videoBox .thumb {
    width: 30%;

}

.videoBox .thumb img {
    height: 7rem;
    width: 100%;
    object-fit: contain;
    border: 1px solid #ebe7e7;
}

.videoBox .desc {
    width: 70%;
    height: 100%;
}

.videoBox .desc h5 {
    font-weight: 400;
    color: #3e3e3e;
}

.newVideoFeed {
    padding: 10px;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;

}


.pid-fields fieldset {
    top: 0;
    border-radius: 5px;
}

.pid-fields legend {
    opacity: 0;
    display: none;
}




.review-video .video-react .video-react-poster {}

.review-video .video-react.video-react-fluid {
    height: 100%;
}

.review-video .video-react-button {
    right: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    top: 0
}

.influencer-reviews-main .campaign-header.col-12 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    gap: 5px;
}

.review-video .review_react_player .react-player__preview {
    height: 100%;
    background-position: center center !important;
    background-size: contain !important;
    background-color: black !important;
    background-repeat: no-repeat !important;
}

.review-video .review_react_player video {
    background-color: black !important;
}

.event-txt {
    margin-right: 10px;
    color: grey;
    font-size: 13px;
}

.review_react_player.objectFit video {
    object-fit: cover !important;
}

.review_react_player.bg-black {
    background-color: black !important;
}



.reviews-list-main {
    display: flex;
    flex-direction: row;
    gap: 1%;
    flex-wrap: wrap;
    width: 100%;
}

.reviews-list-box {
    margin-bottom: 15px;
    min-width: 24%;
    max-width: 24%;
}

.reviews-list-box .title-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    justify-content: flex-start;

}

.reviews-list-box .title-area .title-name {
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.reviews-list-box .title-area .pixel-id {
    font-size: 11px;
    font-weight: 400;
}

.reviews-list-box .btm-title {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-family: Nunito Sans, sans-serif !important;
    font-size: 20px;
    height: 52px;
    margin-bottom: 5px;
    margin-top: 10px;
    min-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
}


.review-date-main {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    gap: 5px;
}

.review-date-main .review-date {
    display: flex;
    align-items: center;
}

.review-date-main .review-date .review-icon {
    margin-right: 8px;
    margin-top: -1px;
    color: #7d84a9;
}

.review-date-main .review-date .review-txt {
    margin-right: 10px;
    color: grey;
    font-size: 13px;
}

.review-date-main .review-date .review-txt .ant-statistic-content {
    font-size: 14px;
    color: #eb335b;
}

.review-date-main .review-date .review-txt.recorded-gap {
    margin-right: 2px;
}

.review-date-main .review-date.bottom-style {
    padding-bottom: 0;
}

.review-date-main .review-date.bottom-style .review-icon {
    color: #1a2458;
}

.review-date-main .review-date.bottom-style .review-txt {
    color: #162b4c;
}


.review-content {
    padding: 1.5rem 1.5rem !important;

}

.reviews-box-buttons {
    padding: 0 1.5rem 1.5rem 1.5rem !important;
}

.reviews-box-buttons button {
    width: 100%;
    line-height: initial;
    height: 30px;
}

.reviews-box-buttons button .MuiSvgIcon-root {
    font-size: 15px;
}



@media only screen and (max-width: 1440px) {
    .reviews-list-box {
        min-width: 32%;
        max-width: 32%;
    }
}

@media only screen and (max-width: 1024px) {
    .reviews-list-box {
        min-width: 49%;
        max-width: 49%;
    }
}

@media only screen and (max-width: 767px) {
    .reviews-list-box {
        min-width: 100%;
        max-width: 100%;
    }
}

.dashboard-info-box-main {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 2px rgb(215 215 215 / 26%);
    border: 1px solid #dbdbdb;
    margin-bottom: 15px;
    padding: 5px 10px 5px 35px;
    position: relative;
    text-align: right;
}

.dashboard-info-box-main .total-info-hd {
    color: #798892;
    font-family: Nunito Sans, sans-serif;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
}

.dashboard-info-box-main .value-info-hd {
    color: #575757;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
}

.title-area h1 {
    color: #252525;
    font-size: 25px;
    font-weight: 500 !important;
    letter-spacing: 1px;
    margin-bottom: 0;
    padding: 0;
}

.title h5 {
    color: #252525;
    font-weight: 400 !important;
    letter-spacing: 1px;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0;
}

.dashboard-info-box-main .info-icon-mains {
    bottom: 0;
    color: #e4e7ed;
    font-size: 1.5em;
    left: 10px;
    margin: auto;
    position: absolute;
    top: 0;
}

.dashboard-info-box-main .info-icon-mains.icon-percent {
    font-size: 1.2em;
}

.agreementHeader {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.agreementStatus {
    font-weight: 600;
    font-size: 0.9rem;
    color: #052977;
}

.btn-div {
    display: flex;
    gap: 15px;
}

.btn-dates {
    font-size: 0.8rem;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #bbb8b8;

}

.btn-active {
    font-weight: 500;
    background: #052977;
    color: #fff;
}

.order-status {
    background: gray;
    border-radius: 5px;
    color: #fff;
    font-family: Nunito Sans, sans-serif !important;
    font-size: 9pt;
    font-weight: 400 !important;
    padding: 1px 10px;
    text-transform: capitalize;
}

.status-success {
    color: #fff;
    background: #008000;
}

.status-error {
    color: #fff;
    background: #c91432;
}

.status-warning {
    color: #fff;
    background: #ffbc11;
}

.orderdetail-title {
    font-family: Nunito Sans, sans-serif;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px;
}

.css-2zz5gn-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23) !important;
}

.css-qf8ner.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23) !important;

}

.css-guswg5-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: rgba(0, 0, 0, 0.6) !important;
}

.css-v8hgrd.Mui-error {
    color: rgba(0, 0, 0, 0.6) !important;

}


@media only screen and (max-width: 1900px) and (min-width: 1601px) {

    .dashboard-info-box-main .info-icon-mains {
        font-size: 1.2em;
    }
}


@media only screen and (max-width: 1600px) and (min-width: 1200px) {
    .dashboard-page .col-xl {
        flex: 0 0 20%;
        max-width: 20%;

    }
}

@media only screen and (max-width: 1500px) and (min-width: 1200px) {


    .laptop-width-box {
        flex: 0 0 33% !important;
        max-width: 33% !important;
    }

    .laptop-width-box2 {
        flex: 0 0 66% !important;
        max-width: 66% !important;
    }

    .laptop-width-box3 {
        flex: 0 0 33% !important;
        max-width: 33% !important;
    }
}

@media only screen and (max-width: 1200px) {
    .user-info-modal-edit {
        width: calc(100% - 33.33333%);
    }
}

@media only screen and (max-width: 1024px) {

    .filters-box-main .filters-top-area.break-mob-full {

        flex-direction: column;
        align-items: start;

    }
}

@media only screen and (max-width: 767px) {
    .dashboard-box-main {
        margin-bottom: 15px;
    }

    .dashboard-info-box-main {
        padding: 5px 10px 5px 40px;
        margin-bottom: 10px;

    }

    .dashboard-info-box-main .value-info-hd {
        font-size: 13px;
    }

    .dashboard-info-box-main .total-info-hd {
        font-size: 10px;
    }

    .dashboard-page .col-6:nth-child(even) {
        padding-left: 5px;
    }

    .dashboard-page .col-6:nth-child(odd) {
        padding-right: 10px;
    }
}





@media only screen and (max-width: 1900px) and (min-width: 1400px) {
    .dashboard-date-box {
        flex: 0 0 33% !important;
        max-width: 33% !important;
    }

}

@media only screen and (max-width: 1399px) and (min-width: 1200px) {
    .dashboard-date-box {
        flex: 0 0 38% !important;
        max-width: 38% !important;
    }

    .dashboard-search-buttons {
        flex: 0 0 37% !important;
        max-width: 37% !important;
    }
}



@media only screen and (max-width: 1900px) and (min-width: 1200px) {
    .btn-width.laptop {
        min-width: initial !important;
        min-height: initial !important;
    }
}

.active-discount {
    border: 2px solid green;
}

/* In your CSS file */
/* .MuiDataGrid-columnHeaders {
    height: 20px !important;
    min-height: 20px !important;
  } */

.st-btn {
    font-size: .8rem;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #bbb8b8;
}

.description-limit {
    white-space: break-spaces;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.imgModal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.imgModal:hover {
    background-color: red;
}

.imgModalImg {
    outline: none;
}

.otp-style {
    /* min-height: 175px; */
    padding: 25px 0;
    justify-content: center;
}

.otp-style input {
    width: 50px !important;
    height: 50px;
    margin: 0 .8rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .3)
}

/* .otp-style input:hover {
    outline: none;
} */
/* .otp-style input:focus,.otp-style input:hover {
    outline: none;
} */
.otp-style input:first-child {
    margin-left: 0;
}

.otp-style input:last-child {
    margin-right: 0;
}

.otp-style input[type=number]::-webkit-inner-spin-button,
.otp-style input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.otp-style input::selection {
    background: #ffff;
    color: black;
}

.otp-style input::-moz-selection {
    background: #ffff;
    color: black;
}

.otp-timer {
    text-align: center;
    margin-bottom: 24px;
    font-family: Nunito Sans, sans-serif !important;
    font-weight: 800;
    font-size: 36px;
}

.intro-title {
    display: block !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-family: Nunito Sans, sans-serif !important;
    font-size: 20px;
    height: 52px;
    margin-bottom: 5px;
    margin-top: 10px;
    min-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.intro-title:first-letter {
    text-transform: uppercase;
}

.intro-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.introLoader {
    height: 250px;
    display: flex;
    align-items: center;
    border: 1px solid #e1e1e1;
    justify-content: center;
    background: #e3e3e3;
}


/* //////////////// */
.control-products-listing-main-wrapper {
    max-height: 430px;
}

.control-products-listing-main-wrapper.record-prod-height {
    max-height: 515px;
}

.control-products-listing-main-wrapper .control-products-listing-main {
    position: relative;
    margin-right: 10px;
    /* background-color: #ebebeb;
    */
    /* border-radius: 5px;
    */
    padding: 0 0 10px 0;
    margin: 0 10px 10px 0;
    border-bottom: 1px solid #dce4ec;
}

.control-products-listing-main-wrapper .control-products-listing-main:last-child {
    border-bottom: 0px;
    padding-bottom: 0px;
}

.control-products-listing-main-wrapper .control-products-listing-main .control-products-listing {
    position: relative;
    padding-left: 83px;
    min-height: 90px;
}

.control-products-listing-main-wrapper .control-products-listing-main .control-products-listing .control-product-image {
    width: 68px;
    height: 90px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 0px;
    position: absolute;
    left: 0;
    top: 0;
    line-height: 86px;
    text-align: center;
    background-color: #f9f9f9;
    border: 1px solid #efefef;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.control-products-listing-main-wrapper .control-products-listing-main .control-products-listing .control-product-image img {
    max-width: 100%;
    max-height: 100%;
}

.control-products-listing-main-wrapper .control-products-listing-main .control-products-listing .control-product-content {
    min-height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0;
}

.control-products-listing-main-wrapper .control-products-listing-main .control-products-listing .control-product-content .product-left {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.control-products-listing-main-wrapper .control-products-listing-main .control-products-listing .control-product-content .product-left .control-product-title {
    font-size: 14px;
    font-weight: 700 !important;
    margin-bottom: 0;
    font-family: "Nunito Sans", sans-serif !important;
    max-height: 40px;
    overflow: hidden;
    line-height: initial;
    word-break: break-word;
}

.control-products-listing-main-wrapper .control-products-listing-main .control-products-listing .control-product-content .product-left .control-product-price {
    font-size: 14px;
    margin-bottom: 0;
    font-family: "Nunito Sans", sans-serif !important;
}

.control-products-listing-main-wrapper .control-products-listing-main .control-products-listing .control-product-content .product-right {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
}

.control-products-listing-main-wrapper .control-products-listing-main .control-products-listing .control-product-content .product-right .product-quantity {
    font-size: 14px;
    padding-right: 5px;
}

.modal-body-style {
    max-height: 600px;
    overflow-y: auto;
    padding-right: 2px;
}

.modal-body-style::-webkit-scrollbar {
    width: 10px;
}

.modal-body-style::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 25px #202020; */
    box-shadow: inset 0 0 25px rgba(130, 130, 130, 1);
    border-radius: 5px;
}

.modal-body-style::-webkit-scrollbar-thumb {
    background-color: #052977;
    outline: 1px solid #202020;
    border-radius: 5px !important;
}

@media only screen and (max-width: 1366px) {
    .modal-body-style {
        max-height: 350px;
    }  
}


.bio-profile-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    background-color: #c4e8ff;
}
.bio-profile-bg .new-bio-bg {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.bio-profile-bg .profile-color {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    content: "";
    width: 100%;
    height: 100%
}